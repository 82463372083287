<template>
    <modal ref="modalDetallePago" titulo="Detalle del pago" no-aceptar no-cancelar :tamano="tipo ? 'modal-lg' : ''">
        <div class="row mx-0">
            <div class="col-auto px-2" />
            <div class="col px-2">
                <div class="row mx-0 mb-4">
                    <div class="col d-flex">
                        <img :src="pago.estado == 0 ? '/img/icons/pago_efectivo.svg' : '/img/icons/pago_online.svg'" alt="" />
                        <p class="d-middle ml-2 text-general f-600">{{ separadorNumero(pago.valor) }}</p>
                    </div>
                    <div class="col d-flex justify-content-end">
                        <div class="px-3 d-middle f-14 br-20 bg-general text-white f-600">
                            {{ funEstadoPago() }}
                        </div>
                    </div>
                </div>
                <div class="row mx-0 text-general mb-3">
                    <div class="col text-left">
                        <b>Referencia: </b>
                    </div>
                    <div class="col text-right text-general">
                        {{ pago.referencia }}
                    </div>
                </div>
                <div class="row mx-0 text-general mb-3">
                    <div class="col">
                        <b>Fecha y hora del pago</b>
                    </div>
                    <div class="col text-right">
                        {{ pago.fecha }}
                    </div>
                </div>
                <div class="row mx-0 text-general mb-2">
                    <div class="col">
                        <b>Medio de pago</b>
                    </div>
                    <div class="col text-right">
                        {{ pago.medio_pago ? pago.medio_pago : '---' }}
                    </div>
                </div>
                <div class="row mx-0 text-general mt-2">
                    <div class="col">
                        <b>Observación</b>
                    </div>
                    <div class="col text-right">
                        {{ pago.observacion }}
                    </div>
                </div>
            </div>
            <div v-if="tipo" class="col-auto d-middle">
                <div class="container">
                    <p class="text-general f-14 f-500">Imagen del recibo</p>
                    <inner-image-zoom :src="url_image" :zoom-src="`${url_image}`" class="obj-cover border br-4" style="width:260px;height:170px;" />
                </div>
            </div>
            <div class="col-auto px-2" />
        </div>
    </modal>
</template>

<script>
import pagos from '~/services/pagos/pagos'
export default {

    data(){
        return {
            pago: {},
            url_image: 'https://s3.amazonaws.com/nequi-wp-colombia/wp-content/uploads/2018/01/facebook.jpg',
            tipo: false
        }
    },
    methods: {
        async toggle(pago){
            await this.fetchDetalle(pago)
            this.$refs.modalDetallePago.toggle();
        },
        async fetchDetalle({ id }){
            try {
                const params = {
                    id
                }
                const { data } = await pagos.detallePago(params)
                this.pago = data
            } catch (error){
                this.error_catch(error)
            }
        },
        imgHistorial(estado){
            switch (estado){
            case 1:
                return '/img/icons/pago_efectivo.svg';
            case 2:
                return '/img/icons/pago_online.svg';
            case 3:
                return '/img/icons/pago_online.svg';
            }
        },
        funEstadoPago(){
            switch (this.pago.estado){
            case 0:
            case 2:
                return "Aprobado"
            case 1:
                return "Pendiente"
            case 3:
                return "Rechazado"
            case 9:
                return "No continuado"
            default:
                break;
            }
        }
    },
}
</script>
<style lang="css" scoped>
.aprobado{
    background-color: #27D07B;
    color: #ffffff;
}
.pendiente{
    background-color: #F5F5F5;
    color: #707070;
}
.cancelado{
    background-color: #FF3B63;
    color: #ffffff;
}
</style>
